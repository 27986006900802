import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  param: any;
  value?: string;
  isColumn?: boolean;
}

export const DialogInfoItem: FunctionComponent<Props> = ({
  param,
  value,
  isColumn,
}) => (
  <div>
    <Stack
      direction={isColumn ? 'column' : 'row'}
      justifyContent="space-between"
      spacing={1}
    >
      <Typography
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          color: '#000000',
          cursor: 'pointer',
        }}
        component="text"
      >
        {param}
      </Typography>
      <Typography
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          color: '#000000',
          cursor: 'pointer',
          whiteSpace: 'pre-line',
        }}
        variant="caption"
        component="text"
      >
        {value}
      </Typography>
    </Stack>
  </div>
);

DialogInfoItem.defaultProps = {
  value: '',
  isColumn: false,
};
