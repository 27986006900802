import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import {
  getDefaultAlertProps,
  getIdArray,
  getNameArray,
  getRootLevel,
  replaceQuoat,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { LEVEL_READ, MAIN_COLOR_STRING } from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetInfo } from '../../service/info/apiGetInfo';
import { apiUpdateInfo } from '../../service/info/apiUpdateInfo';
import { MyDateTextField } from '../UI/MyDateTextField';
import { DialogInfoItem } from './DialogInfoItem';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { GroupDto } from '../../dto/GroupDto';
import { AzsTypeDto } from '../../dto/AzsTypeDto';
import { apiGetGroups } from '../../service/group/apiGetGroups';
import { apiGetAzsTypes } from '../../service/azs-type/apiGetAzsTypes';

export const DeviceInfoDiv = () => {
  const { id } = useParams();
  const root = getRootLevel();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [checkHelper, setCheckHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [azsTypeDtos, setAzsTypeDtos] = useState<AzsTypeDto[]>([]);

  const getGroups = async () => {
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getAzsTypes = async () => {
    const apiResult: IApiResult = await apiGetAzsTypes();
    if (apiResult.isSuccess) {
      setAzsTypeDtos(apiResult.data.azsTypes);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getInfo = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetInfo(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      document.title =
        apiResult.data.info.holder !== ''
          ? `${apiResult.data.info.holder}`
          : `${apiResult.data.info.imei}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateInfo = async () => {
    if (deviceInfoDto?.holder === '' || deviceInfoDto?.inn === '') {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    const apiResult: IApiResult = await apiUpdateInfo(deviceInfoDto);
    if (apiResult.isSuccess) {
      setIsEdit(false);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleOpenMap = (x: number, y: number) => {
    window.open(`https://maps.google.com?daddr=${x},${y}`);
  };

  const getAll = async () => {
    await getGroups();
    await getAzsTypes();
    await getInfo(id ?? '');
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('info')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto && `${replaceQuoat(deviceInfoDto?.holder, 0)}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Card
              style={{
                padding: '20px',
                width: '80%',
                background: '#f5f5f5',
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack direction="row">
                    {root !== LEVEL_READ && (
                      <IconButton
                        title={t('tooltipEdit')}
                        size="medium"
                        onClick={() => setIsEdit(!isEdit)}
                      >
                        {!isEdit && (
                          <EditIcon
                            fontSize="medium"
                            sx={{ color: '#f44336' }}
                          />
                        )}
                        {isEdit && (
                          <EditOffIcon
                            fontSize="medium"
                            sx={{ color: '#f44336' }}
                          />
                        )}
                      </IconButton>
                    )}
                    <IconButton
                      disabled={!isEdit}
                      title={t('tooltipSave')}
                      size="medium"
                      onClick={updateInfo}
                    >
                      <SaveIcon
                        fontSize="medium"
                        sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                      />
                    </IconButton>
                  </Stack>
                  <Stack direction="row">
                    <IconButton
                      title={t('tooltipMap')}
                      disabled={
                        deviceInfoDto?.dolgota === 0 &&
                        deviceInfoDto?.shirota === 0
                      }
                      size="medium"
                      onClick={() =>
                        handleOpenMap(
                          deviceInfoDto?.shirota ?? 0,
                          deviceInfoDto?.dolgota ?? 0
                        )
                      }
                    >
                      <MapIcon
                        fontSize="medium"
                        sx={{
                          color:
                            deviceInfoDto?.dolgota !== 0 &&
                            deviceInfoDto?.shirota !== 0
                              ? MAIN_COLOR_STRING
                              : 'gray',
                        }}
                      />
                    </IconButton>
                  </Stack>
                </Stack>

                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {t('titleGeneral')}
                </Typography>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.inn, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            inn: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerInn')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.persAcc, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            persAcc: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerPersAcc')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.holder, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            holder: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerHolder')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.name, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            name: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerName')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.location, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            location: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('headerLocation')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.sert, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            sert: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerSert')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyDateTextField
                      value={`${deviceInfoDto?.sertDateFrom}`}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            sertDateFrom: e.target.value,
                          }
                        )
                      }
                      type="date"
                      disabled={!isEdit}
                      label={t('headerSertFrom')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyDateTextField
                      value={`${deviceInfoDto?.sertDateTo}`}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            sertDateTo: e.target.value,
                          }
                        )
                      }
                      type="date"
                      disabled={!isEdit}
                      label={t('headerSertTo')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyAutoComplete
                      value={
                        deviceInfoDto?.azsType !== ''
                          ? getNameArray(azsTypeDtos)[
                              Object.values(getIdArray(azsTypeDtos)).indexOf(
                                deviceInfoDto?.azsType
                              )
                            ]
                          : ''
                      }
                      onChange={(e, v) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            azsType:
                              getIdArray(azsTypeDtos)[
                                Object.values(
                                  getNameArray(azsTypeDtos)
                                ).indexOf(v)
                              ],
                          }
                        )
                      }
                      error={checkHelper}
                      arrayList={getNameArray(azsTypeDtos)}
                      size="small"
                      disabled={!isEdit}
                      label={t('headerAzsType')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyAutoComplete
                      value={
                        deviceInfoDto?.groupType !== ''
                          ? getNameArray(groupDtos)[
                              Object.values(getIdArray(groupDtos)).indexOf(
                                deviceInfoDto?.groupType
                              )
                            ]
                          : ''
                      }
                      onChange={(e, v) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            groupType:
                              getIdArray(groupDtos)[
                                Object.values(getNameArray(groupDtos)).indexOf(
                                  v
                                )
                              ],
                          }
                        )
                      }
                      error={checkHelper}
                      arrayList={getNameArray(groupDtos)}
                      size="small"
                      disabled={!isEdit}
                      label={t('headerGroup')}
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    marginTop: '20px',
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {t('titleLimit')}
                </Typography>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.presMinLimit}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            presMinLimit: e.target.value,
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label={t('headerMinPres')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.presMaxLimit}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            presMaxLimit: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="number"
                      label={t('headerMaxPres')}
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    marginTop: '20px',
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {t('titleCoordinate')}
                </Typography>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.shirota}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            shirota: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('headerShirota')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.dolgota}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            dolgota: e.target.value,
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerDolgota')}
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    marginTop: '20px',
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {t('titleContact')}
                </Typography>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.phone}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            phone: e.target.value,
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerPhone')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.email}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            email: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('headerEmail')}
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    marginTop: '20px',
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {t('titleVuIden')}
                </Typography>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MyTextFiled
                      value={deviceInfoDto?.vuIden}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            vuIden: e.target.value,
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('headerVuIden')}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Card>

            <Card
              style={{ padding: '20px', width: '20%', background: '#f5f5f5' }}
            >
              <Stack spacing={0}>
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '13px',
                      color: '#000000',
                      letterSpacing: 1,
                      cursor: 'pointer',
                    }}
                    component="text"
                  >
                    {t('titleModem')}
                  </Typography>
                </Stack>
                <Divider />
                <DialogInfoItem
                  param={t('headerImei')}
                  value={deviceInfoDto?.imei}
                />
                <DialogInfoItem
                  param={t('titleNumber')}
                  value={deviceInfoDto?.modemNum}
                />
                <DialogInfoItem
                  param={t('headerFirst')}
                  value={unixToLocal(deviceInfoDto?.dateFirst) ?? ''}
                />
                <DialogInfoItem
                  param={t('headerLastConnect')}
                  value={unixToLocal(deviceInfoDto?.dateConnect) ?? ''}
                />
                <DialogInfoItem
                  param={t('headerConnectNext')}
                  value={unixToLocal(deviceInfoDto?.dateNext) ?? ''}
                />
                <br />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: '#000000',
                    letterSpacing: 1,
                    cursor: 'pointer',
                  }}
                  component="text"
                >
                  {t('titleAgnks')}
                </Typography>
                <Divider />
                <DialogInfoItem
                  param={t('headerAzsVolume')}
                  value={deviceInfoDto?.azsVolume ?? ''}
                />
                <DialogInfoItem
                  param={t('headerColoncaCnt')}
                  value={deviceInfoDto?.coloncaCnt ?? ''}
                />
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
