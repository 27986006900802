import { Card, Divider, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  value: string;
}

export const DeviceValueAzsItem: FunctionComponent<Props> = ({
  title,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <Card style={{ padding: '10px', background: '#b2dfdb' }}>
      <Stack direction="column" width="250px" spacing={1}>
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
          }}
          component="text"
        >
          {title}
        </Typography>
        <Divider />
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
          }}
          component="text"
        >
          {value}
        </Typography>
      </Stack>
    </Card>
  );
};
