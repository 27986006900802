import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  getDefaultAlertProps,
  replaceQuoat,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetInfo } from '../../service/info/apiGetInfo';
import { DeviceValueAzsItem } from './DeviceValueAzsItem';
import { DeviceValueColoncaItem } from './DeviceValueColoncaItem';

export const DeviceValueDiv = () => {
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const getInfo = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetInfo(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      document.title =
        apiResult.data.info.holder !== ''
          ? `${apiResult.data.info.holder}`
          : `${apiResult.data.info.imei}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getInfo(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('tooltipMeasure')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto && `${replaceQuoat(deviceInfoDto?.holder, 0)}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card
            style={{
              padding: '20px',
              background: '#f5f5f5',
            }}
          >
            <Stack spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#ff5722',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {t('infoAllAzs')}
              </Typography>
              <Divider />
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <DeviceValueAzsItem
                    title={t('headerAzsVolume')}
                    value={deviceInfoDto?.azsVolume ?? ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DeviceValueAzsItem
                    title={t('headerConnect')}
                    value={unixToLocal(deviceInfoDto?.dateConnect) ?? ''}
                  />
                </Grid>
              </Grid>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  marginTop: '20px',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#ff5722',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {t('infoColonca')}
              </Typography>
              <Divider />
              <Grid container spacing={1}>
                {deviceInfoDto?.columnArr.map((item) => (
                  <DeviceValueColoncaItem dto={item} />
                ))}
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
