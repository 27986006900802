import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  getDefaultAlertProps,
  getSelectedLng,
  otherToDataGrid,
} from '../../utils/utils';
import { LANGUAGE_RUS, MAIN_COLOR_STRING } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { GroupDto } from '../../dto/GroupDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetGroups } from '../../service/group/apiGetGroups';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiAddGroup } from '../../service/group/apiAddGroup';
import { apiDeleteGroup } from '../../service/group/apiDeleteGroup';
import { DialogConfirmation } from '../confirmation/DialogConfirmation';
import { MyTextFiled } from '../UI/MyTextField';
import { AzsTypeDto } from '../../dto/AzsTypeDto';
import { apiGetAzsTypes } from '../../service/azs-type/apiGetAzsTypes';
import { apiAddAzsType } from '../../service/azs-type/apiAddAzsType';
import { apiDeleteAzsType } from '../../service/azs-type/apiDeleteAzsType';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogSetup: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [groupDeleteDto, setGroupDeleteDto] = useState<GroupDto>({
    id: '',
    name: '',
  });
  const [azsTypeDeleteDto, setAzsTypeDeleteDto] = useState<AzsTypeDto>({
    id: '',
    name: '',
  });
  const [groupDto, setGroupDto] = useState<GroupDto>({
    id: '',
    name: '',
  });
  const [azsTypeDto, setAzsTypeDto] = useState<AzsTypeDto>({
    id: '',
    name: '',
  });
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [azsTypeDtos, setAzsTypeDtos] = useState<AzsTypeDto[]>([]);

  const [checkHelper, setCheckHelper] = useState(false);

  const getGroups = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getAzsTypes = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetAzsTypes();
    if (apiResult.isSuccess) {
      setAzsTypeDtos(apiResult.data.azsTypes);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addGroup = async (dto: GroupDto) => {
    const apiResult: IApiResult = await apiAddGroup(dto);
    if (apiResult.isSuccess) {
      setGroupDto({
        id: '',
        name: '',
      });
      getGroups();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: t('errorGroupConflict'),
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const addAzsType = async (dto: AzsTypeDto) => {
    const apiResult: IApiResult = await apiAddAzsType(dto);
    if (apiResult.isSuccess) {
      setAzsTypeDto({
        id: '',
        name: '',
      });
      getAzsTypes();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: t('errorAzsTypeConflict'),
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      if (valueTab === 0) {
        getGroups();
      }
      if (valueTab === 1) {
        getAzsTypes();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, valueTab]);

  const handleClose = () => {
    onClose();
    setGroupDto({
      id: '',
      name: '',
    });
    setAzsTypeDto({
      id: '',
      name: '',
    });
    setCheckHelper(false);
  };

  const handleAdd = async () => {
    if (groupDto.name === '') {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await addGroup(groupDto);
  };

  const handleAddAzsType = async () => {
    if (azsTypeDto.name === '') {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await addAzsType(azsTypeDto);
  };

  const deleteGroup = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteGroup(groupDeleteDto);
    if (apiResult.isSuccess) {
      setGroupDeleteDto({
        id: '',
        name: '',
      });
      getGroups();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const deleteAzsType = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteAzsType(azsTypeDeleteDto);
    if (apiResult.isSuccess) {
      setAzsTypeDeleteDto({
        id: '',
        name: '',
      });
      getAzsTypes();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: GroupDto) => {
    setGroupDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const confirmDeleteAzsType = (dto: AzsTypeDto) => {
    setAzsTypeDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const handleChangeTab = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueTab(newValue);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('group'),
      renderHeader: () => <strong>{t('group')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => confirmDelete(row.row)}>
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsAzsType: GridColDef[] = [
    {
      field: 'name',
      headerName: t('headerAzsType'),
      renderHeader: () => <strong>{t('headerAzsType')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            onClick={() => confirmDeleteAzsType(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('setupEdit')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label={t('groupEdit')} />
              <Tab label={t('azsTypeEdit')} />
            </Tabs>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            marginTop="20px"
            spacing={1}
          >
            <MyTextFiled
              value={valueTab === 0 ? groupDto?.name : azsTypeDto?.name}
              onChange={(e) =>
                valueTab === 0
                  ? setGroupDto({ ...groupDto, name: e.target.value })
                  : setAzsTypeDto({ ...azsTypeDto, name: e.target.value })
              }
              size="small"
              error={checkHelper}
              label={valueTab === 0 ? t('group') : t('headerAzsType')}
            />
            <Stack alignItems="center">
              <IconButton
                sx={{ color: MAIN_COLOR_STRING }}
                onClick={valueTab === 0 ? handleAdd : handleAddAzsType}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
          {groupDtos.length > 0 && valueTab === 0 && (
            <Stack paddingTop="20px" spacing={1}>
              <Box
                sx={{
                  '& .app-theme-cell': {
                    fontFamily: 'sans-serif',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                        fontSize: '100px',
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '10px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={groupDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherToDataGrid}
                    pageSize={15}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            </Stack>
          )}
          {azsTypeDtos.length > 0 && valueTab === 1 && (
            <Stack paddingTop="20px" spacing={1}>
              <Box
                sx={{
                  '& .app-theme-cell': {
                    fontFamily: 'sans-serif',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                        fontSize: '100px',
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '10px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columnsAzsType}
                    rows={azsTypeDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherToDataGrid}
                    pageSize={15}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
      <DialogConfirmation
        open={openDialogConfirmation}
        message={t('confirmDelete')}
        onClose={valueTab === 0 ? deleteGroup : deleteAzsType}
      />
    </div>
  );
};
