import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import InfoIcon from '@mui/icons-material/Info';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import { SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintIcon from '@mui/icons-material/Print';
import {
  DEVICES_ARCHIVE_ROUTE,
  DEVICES_INFO_ROUTE,
  DEVICES_VALUE_ROUTE,
} from '../../constant/routes';
import i18n from '../../localization/Localization';

const actions = [
  {
    icon: <InfoIcon sx={{ color: '#03a9f4' }} />,
    name: 'Информация',
    tooltip: i18n.t('tooltipInfo'),
  },
  {
    icon: <GasMeterIcon sx={{ color: '#ff5722' }} />,
    name: 'Измерения',
    tooltip: i18n.t('tooltipMeasure'),
  },
  {
    icon: <PrintIcon sx={{ color: '#9c27b0' }} />,
    name: 'Отчет',
    tooltip: i18n.t('tooltipPrint'),
  },
];

export const SpeedDiaFlowmeterlDiv = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = (name: string) => {
    if (name === 'Информация') {
      navigate(`${DEVICES_INFO_ROUTE}/${id}`);
    }
    if (name === 'Измерения') {
      navigate(`${DEVICES_VALUE_ROUTE}/${id}`);
    }
    if (name === 'Отчет') {
      navigate(`${DEVICES_ARCHIVE_ROUTE}/${id}`);
    }
    setOpen(false);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel=""
        sx={{
          '& .MuiFab-primary': {
            backgroundColor: '#9e9e9e',
            width: 5,
            height: 5,
            visibility: 'hidden',
          },
        }}
        direction="right"
        icon={<SpeedDialIcon openIcon={<MoreVertIcon />} />}
        onClick={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            tooltipTitle={action.tooltip}
            icon={action.icon}
            onClick={() => handleClose(action.name)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};
