import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Card,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  replaceQuoat,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  DEVICE_STATUS,
  LANGUAGE_RUS,
  MOBILE_VIEW,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  START_PAGE_SIZE_DEVICES,
  STATE,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DEVICES_INFO_ROUTE } from '../../constant/routes';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetDevices } from '../../service/device/apiGetDevices';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import { apiResetLimit } from '../../service/device/apiResetLimit';

export const DevicesDiv = () => {
  const { search } = useParams();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);

  const title = localStorage.getItem(DEVICE_STATUS) || '';
  const state = localStorage.getItem(STATE) || '';

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDevices(state, search);
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleClearLimit = async (dto: DeviceListDto) => {
    setBackDrop(true);
    const apiResult: IApiResult = await await apiResetLimit(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns: GridColDef[] = [
    {
      field: 'imei',
      headerName: t('headerImei'),
      renderHeader: () => <strong>{t('headerImei')}</strong>,
      minWidth: 250,
      editable: false,
      hide: state !== DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <a
            href={`${DEVICES_INFO_ROUTE}/${row.row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.row.imei}
          </a>
        </Stack>
      ),
    },
    {
      field: 'modemNum',
      headerName: t('headerModemNum'),
      renderHeader: () => <strong>{t('headerModemNum')}</strong>,
      minWidth: 250,
      editable: false,
      hide: state !== DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => row.row.modemNum,
    },
    {
      field: 'azsId',
      headerName: t('headerAzsId'),
      renderHeader: () => <strong>{t('headerAzsId')}</strong>,
      flex: 1,
      minWidth: 250,
      editable: false,
      hide: state !== DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => row.row.azsId,
    },
    {
      field: 'holder',
      headerName: t('headerHolder'),
      renderHeader: () => <strong>{t('headerHolder')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide: state === DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <a
            href={`${DEVICES_INFO_ROUTE}/${row.row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {replaceQuoat(row.row.holder, 0)}
          </a>
        </Stack>
      ),
    },
    {
      field: 'location',
      headerName: t('headerLocation'),
      renderHeader: () => <strong>{t('headerLocation')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide: state === DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => replaceQuoat(row.row.location, 0),
    },
    {
      field: 'grupName',
      headerName: t('headerGroup'),
      renderHeader: () => <strong>{t('headerGroup')}</strong>,
      minWidth: 250,
      flex: 1,
      editable: false,
      hide: state === DeviceStageEnum.UNREG.toString(),
      renderCell: (row) => row.row.grupName,
    },
    {
      field: 'azsVolume',
      headerName: t('headerAzsVolume'),
      renderHeader: () => <strong>{t('headerAzsVolume')}</strong>,
      minWidth: 200,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.azsVolume,
    },
    {
      field: 'coloncaCnt',
      headerName: t('headerColoncaCnt'),
      renderHeader: () => <strong>{t('headerColoncaCnt')}</strong>,
      minWidth: 200,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.coloncaCnt,
    },
    {
      field: 'dateConnect',
      headerName: t('headerConnect'),
      renderHeader: () => <strong>{t('headerConnect')}</strong>,
      minWidth: 200,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => (
        <div style={{ color: row.row.isConnect === 1 ? '#ef5350' : undefined }}>
          {unixToLocal(row.row.dateConnect)}
        </div>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      hide: state !== DeviceStageEnum.LIMIT.toString(),
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipClear')}
            onClick={() => handleClearLimit(row.row)}
          >
            <ClearIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        marginBottom="20px"
        spacing={2}
      >
        <Stack width="90%">
          <BodyHeaderDiv title={title} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Card
            style={{ padding: '20px', height: '100%', background: '#f5f5f5' }}
          >
            <Box
              height="100%"
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    background: '#f5f5f5',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={deviceDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_DEVICES}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
