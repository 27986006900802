import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSelectedLng } from '../utils/utils';
import { LANGUAGE_RUS } from '../constant/constants';

i18n.use(initReactI18next).init({
  resources: {
    rus: {
      translation: {
        // Статусы API
        errotNotFoundAccount:
          'Такого личного кабинет нет. Проверьте логин и пароль',
        errotServer:
          'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже',

        // Авторизация
        signIn: 'Авторизация',
        login: 'Логин',
        password: 'Пароль',
        enter: 'Войти',

        // Главное окно
        dashboard: 'Приборная панель',
        flowSystem: 'АГНКС в системе',
        flowConnect: 'Потеря связи',
        info: 'Информация',
        report: 'Отчеты',

        // Подсказки
        tooltipExit: 'Выйти',
        tooltipInfo: 'Информация',
        tooltipPrint: 'Отчеты',
        tooltipEdit: 'Редактирвоать',
        tooltipSave: 'Сохранить',
        tooltipLook: 'Сформировать',

        // Личные кабинеты
        loginName: 'Имя пользователя',
        read: 'Чтение',
        readWrite: 'Чтение и запись',
        admin: 'Администратор',
        root: 'Уровень доступа',

        // Таблица устройств
        headerAzsId: 'Идентификатор АГНКС',
        headerName: 'Обозначение',
        headerHolder: 'Потребитель',
        headerLocation: 'Адрес установки',
        headerAzsVolume: 'Объем ст., м³',
        headerAzsDatetime: 'Дата и время подключения',
        headerAzsMassa: 'Масса, т',
        headerAzsSum: 'Сумма, сум',
        headerColoncaCnt: 'Количество колонок',
        headerFirst: 'Первое подключение',
        headerConnect: 'Подключение',
        headerLastConnect: 'Последнее подключение',
        headerConnectNext: 'Следующее подключение',
        headerPhone: 'Номер телефона',
        headerEmail: 'Почта',

        // Информация по АЗС
        infoAllAzs: 'Общая по АГНКС',
        infoColonca: 'Объемы по колонкам',

        // Отчет
        reportPeriod: 'Отчетный период',
        day: 'День',
        month: 'Месяц',
        year: 'Год',
        date: 'Дата',

        // NEWWWWW
        // Надпись под полем ввода
        enterValue: 'Введите значение',
        headerImei: 'IMEI',

        flowUnreg: 'Не зарегистрированные АГНКС',
        flowReg: 'Зарегистрированные АГНКС',
        flowLimit: 'Выход параметров за лимиты',
        flowSearch: 'Результаты поиска',
        flowSertifivate: 'Истекает поверка',
        headerModemNum: 'Заводcкой номер модема',
        headerInn: 'ИНН',
        headerSert: 'Сертификат',
        headerSertTo: 'Действует до',
        headerSertFrom: 'Выдан от',
        headerPersAcc: 'Лицевой счет',
        headerMinPres: 'Минимальное давление',
        headerMaxPres: 'Максимальное давление',
        headerVuIden: 'Идентификатор ВУ (IMEI модема)',
        headerColonca: 'Колонка №',
        headerAddres: 'Адрес',
        headerDolgota: 'Долгота',
        headerShirota: 'Широта',
        headerAzsType: 'Тип колонки',
        headerGroup: 'Группа',

        titleGeneral: 'Общая',
        titleContact: 'Контакты',
        titleLimit: 'Контроль границ',
        titleModem: 'Модем',
        titleNumber: 'Номер',
        titleAgnks: 'АГНКС',
        tooltipMeasure: 'Измерения',
        titleVuIden: 'Информация по ВУ',
        titleCoordinate: 'Координаты',
        tooltipPdf: 'Сохранить PDF',
        tooltipExcel: 'Сохранить Excel',
        tooltipSetup: 'Настройки',
        errorGroupConflict: 'Такая группа существует',
        group: 'Группа',
        setupEdit: 'Настройки',
        groupEdit: 'Редактирование групп',
        groupList: 'Список групп',
        groupListEmpty: 'Список групп пуст',
        confirmDelete: 'Вы действительно хотите удалить данную запись?',
        cancel: 'Отмена',
        delete: 'Удалить',
        azsTypeEdit: 'Редактирование типов колонок',
        azsTypeList: 'Список типов колонок',
        azsTypeListEmpty: 'Список типов колонок пуст',
        errorAzsTypeConflict: 'Такой тип колонки существует',
        tooltipClear: 'Убрать из списка',
        search: 'Поиск',
        tooltipMap: 'Открыть на карте',

        // Расшифровка ошибок
        errFlowMax: '1 - Превышен максимальный расход',
        errTempLimit: '2 - Температура вышла за пределы контроля',
        errMagnet: '3 - Обнаружено воздействие магнита',
        errCalibErr: '4 - Ошибка калибровки канала температуры',
        errBox: '5 - Открыт основной отсек',
        errAdc: '6 - Отсутствует измерительный модуль',
        errBox2: '7 - Открыт батарейный отсек',

        // Сервис
        headerServiceDeviceNum: 'Зав. номер',
        headerServiceImei: 'IMEI',
        headerServiceHolder: 'Потребитель',
        headerServiceVoltBatMain: 'Vпит осн., В',
        headerServiceVoltBatReserv: 'Vпит рез., В',
        headerServiceVoltProc: 'Vпроц, В',
        headerServiceTempProc: 'Tпроц, °С',
        headerServiveFwVer: 'Прошивка',
        headerServiceGprsSendCnt: 'GPRS кол. отпр.',

        // Окно подтверждения
        confirmDeleteFlowmeter:
          'Вы действительно хотите удалить данный счетчик?',
        confirmDeleteUser:
          'Вы действительно хотите удалить данного пользователя?',
        confirmWindowTitle: 'Требуется подтверждение действия',

        // Список приборов
        headerListPres: 'Абс. давл., кПа',
        headerListTemp: 'Темп., °C',
        headerListComp: 'Коэф. сжим.',
        headerListSumVolSt: 'Объем ст., м³',

        // Окно архивов и журналов
        headerArchDatetime: 'Дата и время',
        headerArchAbsPres: 'Абс. давл., кПа',
        headerArchAtmPres: 'Атм. давл., кПа',
        headerArchStatus: 'Статус регистров',
        headerArchCode: 'Код записи',
        headerArchTemp: 'Темп., °C',
        headerArchComp: 'Коэф. сжим.',
        headerArchVolSt: 'Объем ст., м³',
        headerArchVolErrSt: 'Возм. объем ст., м³',
        headerArchVol: 'Объем раб., м³',
        headerArchVolErr: 'Возм. объем раб., м³',
        headerEventsDatetime: 'Дата и время',
        headerEventsCode: 'Событие',
        headerEvents: 'Сообщение',
        headerChangesDatetime: 'Дата и время',
        headerChangesCode: 'Параметр',
        headerChangesOldValue: 'Старое значение',
        headerChangesNewValue: 'Новое значение',
        headerChangesStatus: 'Статус замков',
        headerSetupParam: 'Настройка',
        headerSetupValue: 'Значение',

        // Общее
        flowMeter: 'Прибор',

        // Групповой отчет
        groupReport: 'Групповой отчет',
        reportType: 'Тип отчета',
        documentFormat: 'Формат документа',

        beginPeriod: 'Начало периода',
        endPeriod: 'Конец периода',
        form: 'Сформировать',
        hourArchive: 'Часовой',
        dayArchive: 'Суточный',
        monthArchive: 'Месячный',
        eventsJournal: 'События',
        changesJournal: 'Изменения',

        // Групповая задача
        groupTask: 'Групповая задача',
        taskListEmpty: 'Список задач пуст',
        taskList: 'Список задач для отправки',
        taskDone: 'Задать',
        setup: 'Настройка',
        value: 'Значение',

        // Редактирвоание групп
        save: 'Сохранить',
        name: 'Наименование',
        notChoose: 'Не выбрана',

        // Информация и настройки
        titleUpp: 'Условно-постоянные и подстановочные значения',
        titleContr: 'Договорные значения',
        titleServer: 'Настройки сервера',
        titleOther: 'Другие настройки',
        titleControl: 'Действия при срабатывании контроля',
        titleSystem: 'Системные настройки',

        password1: 'Пароль потребителя',
        password2: 'Пароль поставщика',
        resetMagnet: 'Сброс ошибки воздейсвтия магнита',
        resetBox: 'Сброс ошибки открытия основного отсека',
        resetFlowMax: 'Сброс ошибки максимального расхода',
        resetTempLimit: 'Сброс ошибки предела контроля температуры',
        resetAdcd: 'Сброс ошибки измерительного модуля',
        resetCalibErr: 'Сброс ошибки калибровки температуры',
        resetBox2: 'Сброс ошибки открытия батарейного отсека',
        resetBattery: 'Сброс остаточного заряда',
        syncdatetime: 'Синхронизировать время',
        gmt: 'Часовой пояс',
        edit: 'Редактировать',
        setups: 'Настройки',
        holder: 'Потребитель',
        address: 'Адрес установки',
        deviceType: 'Тип устройства',
        deviceNum: 'Заводской номер',
        sertDate: 'Дата поверки',
        constPres: 'Условно-постоянное значение абсолютного давления, кПа',
        constKomp: 'Условно-постоянное значение коэффициента сжимаемости',
        tempMeasPeriod: 'Период измерения температуры, с',
        contrVal:
          'Договорное значение расхода в стандартных условиях (С.У.), м³/ч',
        contrHour: 'Контрактный час',
        contrDay: 'Контрактный день',
        dispOn: 'Дисплей всегда включен',
        dispRotate: 'Автоматическая прокрутка меню дисплея',
        minTempLimit: 'Нижняя граница контроля температуры, °C',
        constMinTemp:
          'Подстановочное значение темп. при выходе за нижнюю границу контроля, °C',
        actionMinTemp:
          'Действие при выходе температуры за нижний предел контроля',
        maxTempLimit: 'Верхняя граница контроля температуры, °C',
        constMaxTemp:
          'Подстановочное значение темп. при выходе за верхнюю границу контроля, °C',
        actionMaxTemp:
          'Действие при выходе температуры за верхний предел контроля',
        actionMagnet: 'Действие при воздействии магнитом на счётный механизм',
        actionMinFlow: 'Действие при выходе за минимальный расход счётчика',
        actionMaxFlow: 'Действие при выходе за максимальный расход счётчика',
        server:
          'Адрес сервера (в формате [ip-адрес]:[порт], например 192.168.1.1:8000)',
        minSign: 'Минимальный уровень сигнала',
        minGprsTemp: 'Минимальная температура отправки GPRS',
        repeatCount: 'Количество повторов отправки',
        contract: 'Договор',
        inn: 'ИНН',
        persAcc: 'Лицевой счет',
        kategory: 'Категория',
        phone: 'Телефон',
        email: 'Почта',
        infoDevice: 'Информация по счетчику',
        imei: 'IMEI',
        procId: 'ID процессора',
        fwVers: 'Версия ПО',
        voltBat: 'Напряжение батарейки, В',
        voltProc: 'Напряжения процессора, В',
        tempProc: 'Температура процессора, °С',
        timeLeft: 'Остаточный заряд, %',
        flowMeterValues: 'Показания счетчика',
        temp: 'Температура, °С',
        pres: 'Абсолютное давление, кПа',
        komp: 'Коэффициент сжимаемости',
        sumVolSt: 'Накопленный объем (ст. усл.), м³',
        events: 'События',
        datetime: 'Дата и время',
        plombNum: 'Электронная пломба',
        plombBroken: 'Пломба сорвана',
        stats: 'Статистика',
        timeTotal: 'Общее время работы, ч',
        timeNewBat: 'Время работы от новой батарейки, ч',
        connect: 'Подключение',
        connectNext: 'След. подключение',
        dateCreate: 'Первое',
        dateLastCon: 'Последнее',
        dateNextCon: 'Следующее',
        actionJournalOnly: 'Только запись в журнал',
        actionContrVal: 'Использовать договорное значение расхода в С.У.',
        actionConstTemp: 'Использовать подстановочное значение температуры',
        dispOnStatusOff: 'Отключен',
        dispOnStatusOn: 'Включен',
        dispRotateStatusOff: 'Отключена',
        dispRotateStatusOn: 'Включена',
        people: 'Физическое лицо',
        noError: 'Нет ошибок',
        factory: 'Юридическое лицо',

        // График
        chart: 'Графики',
        lineChart: 'Линейный график',
        diagramma: 'Диаграмма',
        areaChart: 'Закрашенный график',
        volumeChart: 'Объем ст., м³',
        tempChart: 'Температура, °C',
        dateTimeChart: 'Дата и время',

        // Логи
        logDeleteUser: 'Удален пользователь',
        logLogout: 'Выход из кабинета',
        logSignIn: 'Авторизация',
        logSignUp: 'Добавлен новый пользователь',
        logAddGroup: 'Добавлена новая группа',
        logDeleteGroup: 'Группа удалена',
        logAddGroupTask: 'Добавлена групповая задача',
        logUpdtateUserFlowmeterGroupId:
          'Обновлена информация привязки пользователя к счетчикам',
        logDeleteDevice: 'Удален счетчик',
        logMoveToArch: 'Счетчик переведен в архив',
        logUpdateInfo: 'Обновление информации о счетчике',
        logUpdateSetup: 'Обновление задачи счетчика',
        logDeleteSetup: 'Удаление задачи счетчика',
        logUpdateFw: 'Задано обновление счетчика',
        logDeleteUpdateFw: 'Отменено обновление счетчика',
      },
    },
    uz: {
      translation: {
        // Статусы API
        errotNotFoundAccount:
          "Bunday shaxsiy kabinet yo'q. Login va Parolni tekshirib ko'ring",
        errotServer:
          "Noma'lum xatolik yuz berdi. Keyinroq qayta urinib ko'ring",

        // Авторизация
        signIn: 'Avtorizatsiya',
        login: 'Login',
        password: 'Parol',
        enter: 'Kirish',

        // Главное окно
        dashboard: 'Pribor paneli',
        flowSystem: 'Tizimdagi AGNKS',
        flowConnect: 'Aloqa uzildi',

        // Подсказки
        tooltipExit: 'Chiqish',
        tooltipInfo: "Ma'lumot",
        tooltipPrint: 'Hisobotlar',
        tooltipEdit: 'Tahrirlash',
        tooltipSave: 'Saqlash',
        tooltipLook: 'Shakllantirish',

        // Личные кабинеты
        loginName: 'Foydalanuvchi nomi',
        read: 'Oqish',
        readWrite: "O'qish va saqlash",
        admin: 'Administrator',
        root: 'Vakolat darajasi',
        info: "Ma'lumot",
        report: 'Hisobotlar',

        // Таблица устройств
        headerAzsId: 'AGNKS №',
        headerName: 'Belgilanishi',
        headerHolder: "Iste'molchi",
        headerLocation: "O'rnatilgan manzili",
        headerAzsVolume: 'Standart hajm, m³',
        headerAzsDatetime: "Bog'lanish vaqti",
        headerAzsMassa: 'Massa, t',
        headerAzsSum: 'Summa, sum',
        headerColoncaCnt: 'Kolonkalar soni',
        headerFirst: 'Birinchi ulanish',
        headerConnect: 'Ulanish',
        headerLastConnect: "So'nggi ulanish",
        headerConnectNext: 'Navbatdagi ulanish',
        headerPhone: 'Telefon raqami',
        headerEmail: 'Pochta',

        // Информация по АЗС
        infoAllAzs: "AGNKS bo'yicha jami",
        infoColonca: 'Kolonkalar bo\'yicha gaz hajmi"',

        // Отчет
        reportPeriod: 'Hisobot davri',
        day: 'Kun',
        month: 'Oy',
        year: 'Yil',
        date: 'Vaqt',
      },
    },
  },
  lng: getSelectedLng(),
  fallbackLng: LANGUAGE_RUS,
});

export default i18n;
