import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  DEVICES_ARCHIVE_ROUTE,
  DEVICES_INFO_ROUTE,
  DEVICES_ROUTE,
  DEVICES_VALUE_ROUTE,
  MAIN_ROUTE,
  SIGN_IN_ROUTE,
} from '../../constant/routes';
import { DevicesPage } from '../../page/DevicesPage';
import { MainPage } from '../../page/MainPage';
import { SignInPage } from '../../page/SignInPage';
import { DeviceInfoPage } from '../../page/DeviceInfoPage';
import { DeviceArchivePage } from '../../page/DeviceArchivePage';
import { DeviceValuePage } from '../../page/DeviceValuePage';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={DEVICES_ROUTE} element={<DevicesPage />} />
      <Route path={`${DEVICES_ROUTE}/:search`} element={<DevicesPage />} />
      <Route path={`${DEVICES_INFO_ROUTE}/:id`} element={<DeviceInfoPage />} />
      <Route
        path={`${DEVICES_VALUE_ROUTE}/:id`}
        element={<DeviceValuePage />}
      />
      <Route
        path={`${DEVICES_ARCHIVE_ROUTE}/:id`}
        element={<DeviceArchivePage />}
      />
    </Routes>
  </div>
);

export default App;
