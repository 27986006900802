import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  AppBar,
  Box,
  createTheme,
  IconButton,
  InputBase,
  Stack,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import {
  DEVICES_ROUTE,
  MAIN_ROUTE,
  SIGN_IN_ROUTE,
} from '../../constant/routes';
import {
  getDefaultAlertProps,
  getDeviceStageDescription,
  getRootDescription,
  getRootLevel,
  getViewType,
} from '../../utils/utils';
import { IAlertProps } from '../../interface/IAlertProps';
import { MyAlert } from '../UI/MyAlert';
import {
  DEVICE_STATUS,
  FULLNAME_NAME,
  MOBILE_VIEW,
} from '../../constant/constants';
import { DialogSetup } from '../dialog/DialogSetup';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const MainNavigationBar = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const handleClick = () => {
    localStorage.setItem(
      DEVICE_STATUS,
      getDeviceStageDescription(DeviceStageEnum.SEARCH)
    );
    navigate(`${DEVICES_ROUTE}/${search}`);
  };
  const [openDialogSetup, setOpenDialogSetup] = useState(false);
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const root = getRootLevel();
  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#009688',
      },
    },
  });

  const handleOpenSetup = () => {
    setOpenDialogSetup(true);
  };

  const handleCloseSetup = () => {
    setOpenDialogSetup(false);
  };

  const logout = async () => {
    navigate(SIGN_IN_ROUTE);
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleSetup = () => {
    handleOpenSetup();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    onClick={() => navigate(MAIN_ROUTE)}
                  >
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'revert',
                          fontWeight: 750,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        TM-
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 100,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        AGNKS
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={4}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon
                      style={{ cursor: 'pointer' }}
                      onClick={handleClick}
                    />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={search}
                    placeholder={t('search')}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter' || ev.key === 'NumpadEnter') {
                        handleClick();
                        ev.preventDefault();
                      }
                    }}
                    style={{
                      fontFamily: 'sans-serif',
                      fontSize: '18px',
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
                {getViewType() !== MOBILE_VIEW && (
                  <Stack direction="column" alignItems="end" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        {t('loginName')}:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        {`${localStorage.getItem(FULLNAME_NAME)}`}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        {t('root')}:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        {`${getRootDescription(root)}`}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="large"
                    title={t('tooltipSetup')}
                    edge="start"
                    color="inherit"
                    onClick={handleSetup}
                    aria-label="menu"
                  >
                    <SettingsIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    title={t('tooltipExit')}
                    edge="start"
                    color="inherit"
                    onClick={handleLogout}
                    aria-label="menu"
                  >
                    <LogoutIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <DialogSetup open={openDialogSetup} onClose={handleCloseSetup} />
    </Box>
  );
};
